.fc-button {
    background: $__light__vampire;
    border: $__none;
    color: $__granite__gary;
    text-transform: $__capitalize;
    box-shadow: $__none ;
    height: $__auto ;
    @include rounded(3px);
    @include margin-padding(0 3px !important, 6px 12px);
}
.fc-state-down, 
.fc-state-active, 
.fc-state-disabled {
    background-color: $__primarycolor !important;
    color: $__white !important;
    text-shadow: $__none !important;
}
.fc-toolbar {
    h2 {
        font-size: $__font__size__18;
        font-weight: $__semibold;
        line-height: 30px;
        text-transform: $__uppercase;
    }
}
th.fc-widget-header {
    background: $__cloud__gray;
    font-size: $__font__size__14;
    line-height: 20px;
    text-transform: $__uppercase;
    border-color: $__light__water__white;
    @include margin-padding(null, 10px 0);
}
.fc-event {
    border: $__none;
    cursor: $__move;
    font-size: $__font__size__13;
    text-align: $__center;
    @include rounded(2px);
    @include margin-padding(1px 7px, 5px);
}


//new

$color_1: #797979;
$color_2: #000;
$color_3: white;
$color_4: #fff;
$background-color_1: #00c5fb;
$background-color_2: #ff9b44;
$background-color_3: #eeeeee;
$background-color_4: #FF7437;
$border-color_1: rgb(232 234 236);
$border-color_2: #f3f3f3;
$border-color_3: #FF7437;

.fc {
	.fc-toolbar.fc-header-toolbar {
		margin-bottom: 1em !important;
	}
	.fc-button-primary {
		border-color: $border-color_1 !important;
		&:disabled {
			color: $color_4 !important;
			background-color: $background-color_2 !important;
			text-transform: capitalize !important;
		}
	}
	.fc-col-header-cell-cushion {
		color: $color_2 !important;
	}
	.fc-daygrid-day-number {
		color: $color_2 !important;
	}
	.fc-button-group {
		>.fc-button {
			text-transform: capitalize !important;
			&:focus {
				background-color: $background-color_2 !important;
				color: $color_3 !important;
				text-transform: capitalize;
			}
			&:active {
				background-color: $background-color_2 !important;
				color: $color_3 !important;
				text-transform: capitalize;
			}
		}
		>.fc-button.fc-button-active {
			background-color: $background-color_2 !important;
			color: $color_3 !important;
			text-transform: capitalize;
		}
	}
	.fc-view-harness {
		height: 500px !important;
	}
}
.fc-button {
	background: #f1f1f1 !important;
	border: none;
	color: $color_1 !important;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}
.fc-direction-ltr {
	.fc-daygrid-event {
		.fc-event-time {
			font-weight: bold !important;
			color: $color_3 !important;
		}
	}
}
.fc-daygrid-dot-event {
	.fc-event-title {
		font-weight: normal !important;
		color: $color_3 !important;
	}
}
.fc-state-down {
	background-color: $background-color_1 !important;
	color: $color_4 !important;
	text-shadow: none !important;
}
.fc-state-active {
	background-color: $background-color_1 !important;
	color: $color_4 !important;
	text-shadow: none !important;
}
.fc-state-disabled {
	background-color: $background-color_1 !important;
	color: $color_4 !important;
	text-shadow: none !important;
}
.fc-toolbar {
	h2 {
		font-size: 18px !important;
	}
}
.fc-col-header {
	background-color: $background-color_3 !important;
	font-size: 14px !important;
	line-height: 35px !important;
	padding: 10px 0;
	text-transform: uppercase;
}
.fc-theme-standard {
	td {
		border-color: $border-color_2 !important;
		border: 1px solid var(--fc-border-color, #ddd);
	}
	th {
		border-color: $border-color_2 !important;
		border: 1px solid var(--fc-border-color, #ddd);
	}
	.fc-scrollgrid {
		border-color: $border-color_2 !important;
		border: 1px solid var(--fc-border-color, #ddd);
	}
}
.fc-col-header-cell {
	background-color: $background-color_3 !important;
}
.fc-daygrid-event-dot {
	border: 0px solid #e9eaea !important;
}
.fc-event {
	line-height: 1.3;
}
.modal-header {
	border-bottom: 0px solid #dee2e6 !important;
	padding: 30px 30px 0 !important;
	justify-content: center !important;
	.close {
		background-color: $background-color_4;
		border-radius: 50%;
		color: $color_4;
		font-size: 20px;
		height: 30px;
		line-height: 20px;
		margin: 0 !important;
		opacity: 1;
		padding: 0 !important;
		position: absolute;
		right: 10px;
		top: 10px;
		width: 30px;
		z-index: 99;
		border-color: $border-color_3;
		border-style: none;
	}
}
.modal-content {
	border-radius: 10px !important;
}
.modal-title {
	font-size: 22px !important;
}
.modal-footer {
	border: 0 !important;
	justify-content: center !important;
	padding: 0 30px 30px !important;
}
.modal-body {
	padding: 30px !important;
}
